// lambdaService.js
import axios from "axios";
let baseURL = process.env.VUE_APP_LAMBDA_URL || ""
const $axios = axios.create({
    baseURL: baseURL + "/.netlify/functions",
    timeout: 10000 // 10 seconds
});

// Response Interceptor to handle and log errors
$axios.interceptors.response.use(
    response => response,
    error => Promise.reject(error)
);

export default {
    fetchDevices() {
        return $axios.get("getDevices").then(response => response.data);
    },
    fetchEvents() {
        return $axios.get("getEvents").then(response => response.data);
    },
    submitDevice(device) {
        console.log(device);
        return $axios
            .post("submitDevice", device)
            .then(response => response, err => err.response);
    }
};
