<template>
    <div>
        <perfect-scrollbar>
            <div class="eventGrid">
                <EventCard
                    v-for="event in events"
                    :key="event.id"
                    :event="event"
                    class="fade-in"
                /> 
            </div>
                
        </perfect-scrollbar>
    </div>
</template>
<script>
import EventCard from "@/components/EventCard";
import MagicGrid from "@/utils/magic-grid";
import lambdaService from "@/lambdaService";
export default {
    name: "DeviceList",
    components: {
        EventCard
    },
    props: {},
    data() {
        return {
            maxCols: 1,
            magicGrid: null,
            events: []
        };
    },
    mounted() {
        lambdaService.fetchEvents().then(response => {
            this.events = response.records.sort(function(a, b) {
                return parseInt(a.id) - parseInt(b.id);
            });
        });
        if (this.magicGrid == null && events.length == 0) {
            this.magicGrid = new MagicGrid({
                container: "#eventsGrid",
                animate: false,
                gutter: 30,
                maxColumns: 1,
                items: this.events.length
            });
            this.magicGrid.listen();
            window.dispatchEvent(new Event("resize"));
        } else if (events.length > 0) {
            this.magicGrid.init();
            window.dispatchEvent(new Event("resize"));
        }
    },
    computed: {}
};
</script>
<style>
.ps {
    height: 1000px;
}
.fade-in-fast {
    opacity: 1;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-out;
    animation-duration: 0.2s;
}
.fade-in {
    opacity: 1;
    animation-name: fadeInWithBorder;
    animation-iteration-count: 1;
    animation-timing-function: cubic-bezier(0.2, -2, 0.8, 2);
    animation-duration: 0.5s;
}
@keyframes fadeInOpacity {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeInWithBorder {
    0% {
        opacity: 0;
        box-shadow: none;
    }
    100% {
        opacity: 1;
        box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
            0 0px 0 1px rgba(10, 10, 10, 0.02);
    }
}
.invisible {
    visibility: hidden;
}
</style>
