<template>
    <div class="">
        <section id="hero" class="hero is-large">
            <div class="hero-head"></div>
            <div class="hero-body is-family-secondary pb-6 mb-6">
                <div class="container has-text-centered">
                    <h1 class="title mb-6 is-size-1">Lab Streaming Layer</h1>
                    <h2 class="title mb-6 is-size-3 has-text-grey">Why LSL?</h2>
                    <h2 class="subtitle">
                        <div class="columns is-centered is-size-4">
                            <div
                                class="column is-three-quarters has-text-justified"
                            >
                                LSL is an open-source networked middleware
                                ecosystem to stream, receive, synchronize, and
                                record neural, physiological, and behavioral
                                data streams acquired from diverse sensor
                                hardware.
                                <br /><br />
                                <p>
                                    It reduces complexity and barriers to entry
                                    for researchers, sensor manufacturers, and
                                    users through a simple, interoperable,
                                    standardized API to connect data consumers
                                    to data producers while abstracting
                                    obstacles such as platform differences,
                                    stream discovery, synchronization and
                                    fault-tolerance.
                                </p>
                                <br /><br />
                                <p>
                                  To get started, check out the <a href="https://labstreaminglayer.readthedocs.io/info/intro.html">introduction</a>
                                  and <a href="https://labstreaminglayer.readthedocs.io/info/getting_started.html">quick start</a>
                                    pages of the LSL documentation, which includes links to a number of tutorials and
                                  helpful resources.
                                </p>
                            </div>
                        </div>
                    </h2>
                    <a
                        href="https://github.com/sccn/labstreaminglayer"
                        target="_blank"
                    >
                        <button class="button is-primary is-large mt-4 px-4">
                            View on Github
                            <GithubIcon
                                class="ml-2 mb-1"
                                :size="32"
                            ></GithubIcon>
                        </button>
                    </a>
                    <div class="row mt-3">
                        <div
                            class="columns is-centered is-multiline is-mobile is-gapless"
                        >
                            <div class="column is-narrow">
                                <gh-btns-watch
                                    slug="sccn/labstreaminglayer"
                                    show-count
                                />
                            </div>
                            <div class="column is-narrow">
                                <gh-btns-star
                                    slug="sccn/labstreaminglayer"
                                    show-count
                                />
                            </div>
                            <div class="column is-narrow">
                                <gh-btns-fork
                                    slug="sccn/labstreaminglayer"
                                    show-count
                                />
                            </div>
                        </div>
                    </div>
                    <br />
                </div>
            </div>
        </section>
        <section id="features" class="notification">
            <div class="container is-bold has-text-centered">
                <div>
                    <h1 class="title pb-5">Features</h1>
                    <div class="columns">
                        <div class="column">
                            <div class="box feature-box">
                                <div class="my-5">
                                    <svg
                                        style="width: 50px; height: 50px"
                                        viewBox="0 0 24 24"
                                    >
                                        <path
                                            fill="currentColor"
                                            d="M21.4 7.5C22.2 8.3 22.2 9.6 21.4 10.3L18.6 13.1L10.8 5.3L13.6 2.5C14.4 1.7 15.7 1.7 16.4 2.5L18.2 4.3L21.2 1.3L22.6 2.7L19.6 5.7L21.4 7.5M15.6 13.3L14.2 11.9L11.4 14.7L9.3 12.6L12.1 9.8L10.7 8.4L7.9 11.2L6.4 9.8L3.6 12.6C2.8 13.4 2.8 14.7 3.6 15.4L5.4 17.2L1.4 21.2L2.8 22.6L6.8 18.6L8.6 20.4C9.4 21.2 10.7 21.2 11.4 20.4L14.2 17.6L12.8 16.2L15.6 13.3Z"
                                        />
                                    </svg>
                                </div>

                                <h1 class="title is-size-4">
                                    Broad Hardware and Software Interoperability
                                </h1>
                                <p class="pt-5">
                                    LSL provides a standardized multi-platform
                                    interface to acquire data from hundreds of
                                    sensor devices used for measuring neural,
                                    physiological, and behavioral activity and
                                    to interface with many software applications
                                    for data processing and visualization.
                                </p>
                            </div>
                        </div>
                        <div class="column">
                            <div class="box feature-box">
                                <div class="my-5">
                                    <SyncIcon :size="50"></SyncIcon>
                                </div>
                                <h1 class="title is-size-4">
                                    Real-time Stream Synchronization
                                </h1>

                                <p class="pt-5">
                                    Easily capture and analyze data from
                                    multiple sensor devices simultaneously. LSL
                                    provides robust millisecond-precision
                                    temporal synchronization of data streams, as
                                    well as the centralized collection, disk
                                    storage and viewing, from any number of
                                    hardware devices and event-generating
                                    systems connected to your local area
                                    network.
                                </p>
                            </div>
                        </div>
                        <div class="column">
                            <div class="box feature-box">
                                <div class="my-5">
                                    <LightningBoltIcon
                                        :size="50"
                                    ></LightningBoltIcon>
                                </div>
                                <h1 class="title is-size-4">
                                    Easy to Use for Researchers and Developers
                                </h1>
                                <p class="pt-5">
                                    Not a programmer? Download pre-built LSL
                                    apps and plugins, or use the vendor-provided
                                    integrations, making it easy to connect
                                    devices and software with LSL without
                                    writing a line of code. Additionally,
                                    multi-language bindings, multi-platform
                                    support, and a simplified API make it easy
                                    for developers to integrate LSL with new
                                    hardware and software.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id="apps" class="notification mb-0">
            <div class="container my-6 is-bold">
                <div class="columns is-mobile is-vcentered is-centered">
                    <div class="column has-text-centered">
                        <h1 class="title">Apps</h1>
                    </div>
                </div>

                <div class="columns is-desktop is-centered is-vcentered">
                    <div class="column is-four-fifths">
                        <div class="filters mb-4">
                            <span
                                v-for="(filter, i) in filters"
                                v-bind:key="i"
                                class="box mx-3 tag has-background-white has-text-grey-darker is-medium"
                            >
                                {{ filter[0] }}: {{ filter[1] }}
                                <button
                                    class="delete is-small"
                                    @click="removeFilter(filter)"
                                ></button>
                            </span>
                        </div>
                    </div>
                </div>
                <b-modal
                    v-model="isSubmitAppModalActive"
                    has-modal-card
                    trap-focus
                    :destroy-on-hide="false"
                    aria-role="dialog"
                    aria-modal
                >
                    <template #default="props">
                        <SubmitAppForm
                            :valueSets="valueSets"
                            @submit-success="isSubmitAppModalActive = false"
                            @close="props.close"
                        ></SubmitAppForm>
                    </template>
                </b-modal>
                <b-modal
                    v-model="isDeviceModalActive"
                    v-if="!!activeDevice"
                    has-modal-card
                    trap-focus
                    :destroy-on-hide="false"
                    aria-role="dialog"
                    aria-modal
                >
                    <template #default="props">
                        <DeviceModal
                            :item="activeDevice"
                            @close="props.close"
                            @launch-correction="launchCorrectionModal"
                        >
                        </DeviceModal>
                    </template>
                </b-modal>
                <b-modal
                    v-model="isSubmitCorrectionModalActive"
                    has-modal-card
                    trap-focus
                    :destroy-on-hide="false"
                    aria-role="dialog"
                    aria-modal
                >
                    <template #default="props">
                        <SubmitCorrectionForm
                            :item="correctionDevice"
                            @close="props.close"
                            :valueSets="valueSets"
                            @submit-success="
                                isSubmitCorrectionModalActive = false
                            "
                        >
                        </SubmitCorrectionForm>
                    </template>
                </b-modal>

                <div class="columns is-mobile">
                    <div class="column has-text-centered">
                        <span class="is-size-7 has-text-grey-light mr-2"
                            >Device / app not listed here?</span
                        >
                        <button
                            @click="isSubmitAppModalActive = true"
                            class="button is-success is-outlined is-rounded is-small"
                        >
                            Submit a new integration
                        </button>
                    </div>
                </div>
                <div class="columns is-vcentered">
                    <div class="column is-half-desktop is-offset-3">
                        <div class="field has-addons is-horizontal">
                            <div
                                class="control has-icons-left"
                                style="width: 100%"
                            >
                                <AutocompleteKeyValue
                                    :searchQuery="searchQuery"
                                    :devices="devices"
                                    :valueSets="valueSets"
                                    :filters="filters"
                                    :count="deviceCount"
                                    :fields="fields"
                                    v-on:add-filter="addFilter"
                                >
                                </AutocompleteKeyValue>
                                <span class="icon is-small is-left">
                                    <MagnifyIcon></MagnifyIcon>
                                </span>
                            </div>
                            <!-- <div class="control">
                                <a class="button is-info">Search</a>
                            </div> -->
                        </div>
                    </div>
                    <div class="column is-2 is-full-mobile">
                        <div class="field">
                            <b-checkbox v-model="certified">
                                Certified apps only
                            </b-checkbox>
                        </div>
                    </div>
                </div>
                <div id="#tilesContainer">
                    <DeviceList
                        :items="devices"
                        :filters="filters"
                        :certified="certified"
                        @update-count="updateDeviceCount"
                        @set-device="setActiveDevice"
                    />
                </div>
            </div>
        </section>
<!--        <section id="events" class="notification">-->
<!--            <div class="container my-6 is-bold">-->
<!--                <div class="columns is-mobile is-vcentered is-centered">-->
<!--                    <div class="column">-->
<!--                        <h1 class="title has-text-centered">-->
<!--                            Community events-->
<!--                        </h1>-->
<!--                    </div>-->
<!--                </div>-->

<!--                <div class="columns is-vcentered">-->
<!--                    <div class="column is-half-desktop is-offset-3">-->
<!--                        <div class="field has-addons is-horizontal">-->
<!--                            <div-->
<!--                                class="control has-icons-left"-->
<!--                                style="width: 100%"-->
<!--                            >-->
<!--                                <span class="icon is-small is-left">-->
<!--                                    <MagnifyIcon></MagnifyIcon>-->
<!--                                </span>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div id="#eventsContainer">-->
<!--                    <EventList />-->
<!--                </div>-->
<!--            </div>-->
<!--        </section>-->
    </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import DeviceList from "@/components/DeviceList";
import EventList from "@/components/EventList";
import AutocompleteKeyValue from "@/components/AutocompleteKeyValue";
import lambdaService from "@/lambdaService";
import GithubButton from "vue-github-button";
import SubmitAppForm from "@/components/SubmitAppForm";
import SubmitCorrectionForm from "@/components/SubmitCorrectionForm";
import DeviceModal from "@/components/DeviceModal";

import MagnifyIcon from "mdi-vue/Magnify.vue";
import SyncIcon from "mdi-vue/Sync.vue";
import LightningBoltIcon from "mdi-vue/LightningBolt.vue";

import GithubIcon from "mdi-vue/Github.vue";
import ForumOutlineIcon from "mdi-vue/ForumOutline.vue";
import BookOpenVariantIcon from "mdi-vue/BookOpenVariant.vue";
import AppsIcon from "mdi-vue/Apps.vue";

export default {
    name: "Home",
    components: {
        DeviceList,
        EventList,
        GithubIcon,
        LightningBoltIcon,
        SyncIcon,
        MagnifyIcon,
        AutocompleteKeyValue,
        SubmitAppForm,
        DeviceModal,
        SubmitCorrectionForm,
    },
    data() {
        return {
            devices: [],
            searchQuery: "",
            fields: [],
            filters: [],
            columns: [],
            deviceCount: 0,
            pageWidth: window.innerWidth,
            certified: false,
            isSubmitAppModalActive: false,

            activeDevice: null,
            isDeviceModalActive: false,

            correctionDevice: null,
            isSubmitCorrectionModalActive: false,
        };
    },
    computed: {
        numColumns: function () {
            return Math.min(5, Math.ceil(this.pageWidth / 400));
        },
        valueSets: function () {
            let devices = this.devices;
            return Object.fromEntries(
                this.fields.map((field) => [
                    field.k,
                    [
                        ...new Set(
                            devices
                                .map((device) => device[field.k])
                                .flat(2)
                                .filter((x) => x != null)
                        ),
                    ].sort(),
                ])
            );
        },
    },
    created() {
        lambdaService.fetchDevices().then((response) => {
            this.devices = response.records.sort(function (a, b) {
                return parseInt(a.id) - parseInt(b.id);
            });
            this.deviceCount = this.devices.length;
            this.columns = Object.keys(this.devices[0]).map((column) => {
                return {
                    field: column,
                    label: column,
                    sortable: true,
                    numeric: true,
                };
            });
            this.fields = [
                { k: "Manufacturer", v: "manufacturer" },
                { k: "Product Name", v: "product" },
                { k: "Signal Type", v: "signal type" },
                {
                    k: "Type",
                    v: "type",
                },
                { k: "Operating Systems", v: "operating system" },
                { k: "Release Date", v: "release date" },
            ];
        });
        this.pageWidth = window.innerWidth;
    },
    mounted() {
        window.addEventListener("resize", () => {
            this.pageWidth = window.innerWidth;
        });
    },
    methods: {
        updateDeviceCount(n) {
            this.deviceCount = n;
        },
        launchCorrectionModal(device) {
            this.correctionDevice = device;
            this.isSubmitAppModalActive = false;
            this.isDeviceModalActive = false;
            this.isSubmitCorrectionModalActive = true;
        },
        removeActiveDevice() {
            this.activeDevice = null;
            this.isDeviceModalActive = false;
        },
        setActiveDevice(payload) {
            let device = payload.device;
            let e = payload.event;
            let elTag = e.target.localName;
            if (
                elTag != "a" &&
                elTag != "svg" &&
                elTag != "path" &&
                e.target.parentNode.localName != "a"
            ) {
                console.log(e, device);
                this.activeDevice = device;
                this.isDeviceModalActive = true;
            }
        },
        addFilter(filter) {
            this.filters.push(filter);
        },
        removeFilter(filter) {
            this.filters = this.filters.filter((f) => f !== filter);
        },
    },
};
</script>
<style lang="scss">
hr {
    background-color: rgb(218, 218, 218);
}
#hero {
    background-size: 100% 45ex;
    background-repeat: no-repeat;
    background-image: url("/bg.jpg");
}
#features {
    padding-bottom: 100px;
    background-color: #ffffff;
}
#apps {
    background-image: radial-gradient(
        circle 1224px at 25.6% 8.8%,
        rgba(255, 255, 255, 1) 0%,
        rgb(229, 236, 243) 100.2%
    );
}
#events {
    background-image: radial-gradient(
        circle 1224px at 25.6% 8.8%,
        rgba(183, 219, 255, 0.5) 0%,
        rgba(26, 107, 182, 0.4) 100.2%
    );
}
.feature-box {
    height: 100%;
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
}
.filters {
    height: 25px;
    width: 100%;
}
</style>
 has-background-white-bis
