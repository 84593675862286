/* eslint-disable max-len */
const re = {
    keyValRegex: new RegExp(
        `(?:(?:(\\b[\\w\\d-()\\s]+)\\s*)(?::\\s*(\\b[\\w\\d-()\\s]*\\b)|\\b))`,
        "ig"
    ),
    replaceRegex: s => {
        return new RegExp(`(.*)(${s})(.*)`, "ig");
    },
    escapeRegex: s => {
        return s.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    }
};

export default {
    ...re
};
