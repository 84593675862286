<template>
    <div>
        <perfect-scrollbar>
            <p class="has-text-centered has-text-grey-light mb-2">
                {{this.itemsFromQuery.length}} result{{this.itemsFromQuery.length == 1? "" : "s"}}
            </p>
            
            <div id="deviceGrid">
                <DeviceCard
                    v-for="item in itemsFromQuery"
                    :key="item.id"
                    :item="item"
                    class="fade-in"
                    @click.native="$emit('set-device', { event: $event, device: item })"
                /> 
            </div>
        </perfect-scrollbar>
    </div>
</template>
<script>
import DeviceCard from "@/components/DeviceCard";
import MagicGrid from "@/utils/magic-grid";
export default {
    name: "DeviceList",
    components: {
        DeviceCard
    },
    props: {
        items: Array,
        filters: Array,
        certified: Boolean
    },
    data() {
        return {
            maxCols: Math.min(5, Math.ceil(window.innerWidth / 600)),
            magicGrid: null
        };
    },
    methods: {},
    computed: {
        itemsFromQuery: function() {
            let filters = this.filters.map(([key, val]) => item => {
                return (
                    !!item[key] &&
                    item[key]
                        .toString()
                        .toLowerCase()
                        .includes(val.toString().toLowerCase())
                );
            });

            if (this.certified) {
                filters.push(item => item["Certified"] == "Yes");
            }
            let result = filters.reduce((d, f) => d.filter(f), this.items);
            this.$emit("update-count", result.length);
            return result;
        }
    },
    mounted: function() {},
    watch: {
        itemsFromQuery: function(newItems, oldItems) {
            if (this.magicGrid == null && oldItems.length == 0) {
                this.magicGrid = new MagicGrid({
                    container: "#deviceGrid",
                    animate: false,
                    gutter: 30,
                    maxColumns: 4,
                    items: 1
                });
                this.magicGrid.listen();
            } else if (newItems.length > 0) {
                this.magicGrid.init();
                window.dispatchEvent(new Event("resize"));
            }
        }
    }
};
</script>
<style>
.ps {
    height: 2000px !important;
}
.fade-in-fast {
    opacity: 1;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-out;
    animation-duration: 0.2s;
}
.fade-in {
    opacity: 1;
    animation-name: fadeInWithBorder;
    animation-iteration-count: 1;
    animation-timing-function: cubic-bezier(0.2, -2, 0.8, 2);
    animation-duration: 0.5s;
}
@keyframes fadeInOpacity {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeInWithBorder {
    0% {
        opacity: 0;
        box-shadow: none;
    }
    100% {
        opacity: 1;
        box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
            0 0px 0 1px rgba(10, 10, 10, 0.02);
    }
}
.invisible {
    visibility: hidden;
}
</style>
