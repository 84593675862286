<template>
  <article

    class="card tile box is-child has-background-white"
    :class="{ invisible: event.disabled }"
>
    <div class="columns mt-3 is-mobile is-gapless is-vcentered">
        <div class="column is-four-fifths">
            <span class="title is-size-5">
                {{ event['Title'] }}
            </span>
        </div>
    </div>
    <hr/>
    <p class="is-size-6 has-text-grey mb-1 ">
        <CalendarIcon></CalendarIcon>
        {{ new Date(event["Date"]).toDateString() }},
        {{ new Date(event["Date"]).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}) }}  
    </p>
    <p class="is-size-6 has-text-grey">
        <MapMarkerIcon></MapMarkerIcon>
        {{ event["Location"] }}
            
    </p>
    <div class="columns mt-3">
        <div class="column is-two-fifths ">
                <img
                    v-if="event['Image']"
                    :alt="event['Location']"
                    :src="
                        event['Image']['0']['thumbnails'][
                            'large'
                        ]['url']
                    "
                    class="py-0 px-0"
                    style="border-radius: 5px"
                />
                <div
                    v-else
                    class="placeholder"
                    >

                </div>
            </div>
        <div class="column">
            <p>{{ event['Description'] }}</p>
        </div>
    </div>
    <div class="columns is-vcentered is-mobile  is-multiline" target="_blank">
        <div class="column is-3-tablet is-full-mobile">
             <a :href="event['Registration URL']" class="no-underline">
                <b-button type="is-success" rounded>
                   
                        Register
                    
                </b-button>
            </a>
        </div>
        <div class="column is-3-tablet is-full-mobile">
            <a :href="event['More information URL']" target="_blank"  class="no-underline">
                <b-button class="button is-info" rounded>
                    More information
                </b-button>
            </a>
        </div>
            
        </div>
            
    </div>
        
</article>
</template>

<script>
import CalendarIcon from "mdi-vue/Calendar.vue";
import MapMarkerIcon from "mdi-vue/MapMarker.vue";

export default {
    name: "EventCard",
    components: {
        CalendarIcon,
        MapMarkerIcon
    },
    props: {
        event: Object
    }
};
</script>
<style lang="scss" scoped>
.no-underline {
    text-decoration: none !important;
}
// .mdi {
//     color: #000000 !important;
//     opacity: 48% !important;
//     transition: 0.2s ease-in-out !important;
//     &:hover {
//         opacity: 84% !important;
//     }
//     &:active {
//         opacity: 95% !important;
//     }
//     &:disabled {
//         opacity: 20% !important;
//     }
// }
</style>
<style lang="scss" scoped>
.card {
    width: 100%;
    margin-bottom: 40px !important;
    margin-top: 40px !important;
}
.placeholder {
    min-width: 250px;
    min-height: 250px;
}
.download {
    position: absolute;
    bottom: 5%;
    left: 30%;
    right: 30%;
}
.dl-link {
    cursor: pointer;
}

.mdi-error {
    color: #cf0000 !important;
    opacity: 84% !important;
    transition: 0.2s ease-in-out !important;
    &:hover {
        opacity: 84% !important;
    }
    &:active {
        opacity: 95% !important;
    }
    &:disabled {
        opacity: 20% !important;
    }
}
</style>
