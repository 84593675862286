/* eslint-disable max-len */ /* eslint-disable max-len */
<template>
    <div>
        <input
            v-model="searchQuery"
            id="searchDevices"
            class="input is-expanded"
            type="text"
            :placeholder="'Search ' + (this.count > 0 ? this.count + ' ' : '') + 'apps'"
            ref="searchBox"
            @keydown.tab.prevent="acceptSuggestion(false)"
            @keydown.enter.prevent="acceptSuggestion(true)"
            @keydown.right.prevent="acceptSuggestion(false)"
            @keydown.down.prevent="nextSuggestion"
            @keydown.up.prevent="prevSuggestion"
            @keydown.esc.prevent="showSuggestions = false"
            @focus="showSuggestions = true"
            @blur="hideSuggestionList"
            @click="updateSuggestions"
        />

        <div :style="{ 'margin-left': suggestionsOffsetX + 'px' }">
            <ul
                v-if="isSuggestingValue"
                class="suggestions table box dropdown-search is-size-6"
                v-show="showSuggestions"
            >
                <li class="has-text-centered">
                    <span class="is-size-7 has-text-grey-light">
                        Enter <KeyboardReturnIcon :width="15" /> searches by
                        wildcard
                    </span>
                </li>
                <li
                    v-for="(value, i) in valueSuggestions"
                    v-bind:key="i"
                    @mouseover="hoverSuggestion(i)"
                    @click="clickSuggestion(i)"
                    class="suggestion fade-in-fast"
                    :class="{
                        highlightedSuggestion: value.v === selectedValue.v,
                    }"
                >
                    <span v-html="value._html" class="noselect"></span>
                    <span
                        v-if="value.v === selectedValue.v"
                        style="float: right"
                        class="is-size-7 has-text-grey-light"
                    >
                        Tab <KeyboardTabIcon :width="15"></KeyboardTabIcon>
                    </span>
                </li>
            </ul>
            <ul
                v-else
                class="suggestions table box dropdown-search is-size-6"
                v-show="showSuggestions"
            >
                <li
                    v-for="(field, i) in fieldSuggestions"
                    v-bind:key="i"
                    @mouseover="hoverSuggestion(i)"
                    @click="clickSuggestion(i)"
                    class="suggestion fade-in-fast"
                    :class="{
                        highlightedSuggestion: field.v === selectedField.v,
                    }"
                >
                    <strong><i>By: </i></strong>
                    <span v-html="field._html" class="noselect"></span>
                    <span
                        v-if="field.v === selectedField.v"
                        style="float: right"
                        class="is-size-7 has-text-grey-light"
                    >
                        Tab <KeyboardTabIcon :width="15"></KeyboardTabIcon>
                    </span>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
import regexUtils from "@/utils/regex";
import KeyboardTabIcon from "mdi-vue/KeyboardTab.vue";
import KeyboardReturnIcon from "mdi-vue/KeyboardReturn.vue";
export default {
    name: "AutocompleteKeyValue",
    components: {
        KeyboardTabIcon,
        KeyboardReturnIcon
    },
    props: {
        devices: {
            type: Array,
            default: Array
        },
        fields: {
            type: Array,
            default: Array
        },
        filters: {
            type: Array,
            default: Array
        },
        valueSets: {
            type: Object,
            default: Object
        },
        count: {
            type: Number,
            default: 0
        }
    },
    computed: {
        // suggestions: function() {
        //   return this.fieldSuggestions + this.deviceSuggestions;
        // },
        selectedField: function() {
            return this.fieldSuggestions[this.selectedFieldIndex];
        },
        selectedValue: function() {
            return this.valueSuggestions[this.selectedValueIndex];
        }
    },
    mounted() {
        var _this = this;
        window.addEventListener("scroll", function(e) {
            _this.showSuggestions = false;
        });
    },
    data() {
        return {
            // eslint-disable-next-line max-len
            fieldSuggestions: this.fields, // key -> html content
            valueSuggestions: [],
            isSuggestingValue: false,
            query: [null, null, null],

            selectedFieldIndex: null,
            selectedValueIndex: null,
            highlightedFieldSuggestion: null,
            highlightedValueSuggestion: null,
            searchQuery: "",
            suggestionsOffsetX: 0,
            showSuggestions: false

            // List of tuple for how to filter the cards
            // Eg [ ['Manufacturer': 'B-Alert'], ... ]
        };
    },
    methods: {
        stringifyQuery: function() {
            const [k, v] = this.query;
            return `${k}: ${!!v ? v : ""}`;
        },
        // isValidSubquery: function([k, v]) {
        //     console.log([k, v]);
        // },
        suggestDevices: function() {
            let keyFormatterRe;
            let valFormatterRe;
            let valueSets = this.valueSets;
            const strong = "$1<strong>$2</strong>$3";
            const [key, val] = this.query;
            keyFormatterRe = regexUtils.replaceRegex(key);
            valFormatterRe = regexUtils.replaceRegex(val);
            if (this.isSuggestingValue) {
                this.valueSuggestions = valueSets[key]
                    .filter(value => value.search(valFormatterRe) != -1)
                    .map(value => {
                        return {
                            _html: value.replace(valFormatterRe, strong),
                            k: val,
                            v: value
                        };
                    });
                let prevIndex = this.valueSuggestions.indexOf(
                    this.selectedValue
                );
                this.selectedValueIndex = prevIndex == -1 ? 0 : prevIndex;
            } else {
                this.fieldSuggestions = this.fields
                    .filter(field => field.k.search(keyFormatterRe) != -1)
                    .map(field => {
                        return {
                            _html: field.k.replace(keyFormatterRe, strong),
                            k: field.k,
                            v: field.v
                        };
                    });
                let prevIndex = this.fieldSuggestions.indexOf(
                    this.selectedField
                );
                this.selectedFieldIndex = prevIndex == -1 ? 0 : prevIndex;
            }
        },
        updateSuggestions: function() {
            if (this.searchQuery == "") {
                this.fieldSuggestions = this.fields.map(field => {
                    return {
                        _html: field.k,
                        k: field.k,
                        v: field.v
                    };
                });
                this.selectedFieldIndex = 0;
            } else if (
                this.isSuggestingValue &&
                !this.searchQuery.includes(":")
            ) {
                this.isSuggestingValue = false;
                this.selectedValueIndex = null;
            } else {
                const keyValRe = this.searchQuery.matchAll(
                    regexUtils.keyValRegex
                );
                const q = Array.from(keyValRe);
                console.log(...q);
                if (q) {
                    const k = q[0][1];
                    const v = !!q[0][2] ? q[0][2] : "";
                    this.query = [k, v];
                    this.suggestDevices();
                    // console.log(`[INFO] Extracted quqery was ${this.query}`);
                } else {
                    console.log(`[ERROR] Regex from search query was ${q}`);
                }
            }
        },
        hoverSuggestion: function(i) {
            if (this.isSuggestingValue) {
                this.selectedValueIndex = i;
            } else {
                this.selectedFieldIndex = i;
            }
        },
        hideSuggestionList: function(e) {
            let blurTarget = e.explicitOriginalTarget;
            if (blurTarget.localName === "article") {
                this.showSuggestions = false;
            }
        },
        clickSuggestion: function(i) {
            if (this.isSuggestingValue) {
                this.selectedValueIndex = i;
            } else {
                this.selectedFieldIndex = i;
            }
            this.acceptSuggestion();
        },
        nextSuggestion: function() {
            if (this.isSuggestingValue) {
                this.selectedValueIndex = Math.min(
                    this.valueSuggestions.length - 1,
                    this.selectedValueIndex + 1
                );
            } else {
                this.selectedFieldIndex = Math.min(
                    this.fieldSuggestions.length - 1,
                    this.selectedFieldIndex + 1
                );
            }
        },
        prevSuggestion: function() {
            if (this.isSuggestingValue) {
                this.selectedValueIndex = Math.max(
                    0,
                    this.selectedValueIndex - 1
                );
            } else {
                this.selectedFieldIndex = Math.max(
                    0,
                    this.selectedFieldIndex - 1
                );
            }
        },
        acceptSuggestion: function(wildcard) {
            if (this.selectedValueIndex == null) {
                this.query[0] = this.selectedField.k;
                this.searchQuery = this.stringifyQuery();
                this.isSuggestingValue = true;
            } else {
                if (wildcard) {
                    if (typeof this.query[1] == "undefined") {
                        this.query[1] = "";
                    }
                    this.query[2] = true;
                } else {
                    this.query[1] = this.selectedValue.v;
                }
                this.selectedValueIndex = null;
                this.isSuggestingValue = false;
                this.$emit("add-filter", Array.from(this.query));
                this.query = [null, null, null];
                this.searchQuery = "";
                this.showSuggestions = false;
            }
        }
    },
    watch: {
        searchQuery: function(newVal, oldVal) {
            this.updateSuggestions();
        }
    }
};
</script>
<style lang="scss" scoped>
#searchDevices::placeholder {
    /* Most modern browsers support this now. */
    color: rgb(20, 17, 20);
}
.dropdown-search {
    position: absolute;
    display: inline-block;
    padding: 0rem;
    min-width: 200px !important;
}
.suggestions {
    width: 300px;
    max-height: 300px !important;
    z-index: 100;
    overflow: auto;
}
.example::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.example {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.suggestion {
    transition: background-color 0.2s ease;
    padding: 0.4rem 1rem;
}
.suggestion:hover {
    background-color: rgb(235, 235, 235) !important;
    min-width: 200px !important;
}
.highlightedSuggestion {
    background-color: rgb(235, 235, 235);
}
.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
strong {
    font-weight: 800;
}
</style>
