<template>
    <div class="modal-card">
        <header class="modal-card-head">
            <p class="modal-card-title">
                Device information
            </p>
            <button
                type="button"
                class="delete"
                @click="$emit('close')"/>
        </header>
        
        <section class="modal-card-body" style="height: 75vh;">
            <div class="columns is-mobile is-multiline">
                <div class="column is-half is-offset-3">
                    <DeviceCard :item="item" :standalone="true"></DeviceCard>
                </div>
                <div class="is-3"></div>
                
                <div class="column is-full">
                    <div class="columns">
                        <div class="column">
                             <p class="is-size-4 has-text-grey pt-3 pb-2 ml-1 title">
                                <InformationOutlineIcon>
                                    
                                </InformationOutlineIcon>
                                Tech Specs
                                
                            </p>
                        </div>
                        <div class="column">
                            <p class="mt-1 has-text-grey-light is-size-7 has-text-right">
                                Found something wrong?
                                </p>
                            <button 
                            @click="$emit('launch-correction', item)"
                            class="button is-success is-rounded is-small is-pulled-right is-outlined">
                                Submit a correction
                            </button>
                        </div>
                    </div>
                   
                    
                    <hr/>
                    
                        <div class="columns is-multiline">
                            
                            <div 
                            v-for="(spec, i) in spec_fields"
                            :key="i"
                            class="column is-full my-0 py-1 bordered">
                                <div class="columns">
                                    <div class="column is-one-third">
                                        {{ spec }}
                                    </div>
                                  <div v-if="spec === 'Release Date'" class="column">
                                    {{item[spec] ? item[spec].slice(0,7) : item[spec]}}
                                  </div>
                                  <div v-else class="column">
                                        {{ stringify(item[spec]) }}
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                </div>
            
            </div>
        </section>
        
    </div>
  
</template>

<script>
import DeviceCard from "@/components/DeviceCard";
import InformationOutlineIcon from "mdi-vue/InformationOutline.vue";
export default {
    name: "DeviceModal",
    data() {
        return {
            spec_fields: [
                "Product Name",
                "Manufacturer",
                "Num Channels",
                "Signal Type",
                "Sampling Rate",
                "Version",
                "Release Date"
            ]
        };
    },
    components: {
        DeviceCard,
        InformationOutlineIcon
    },
    props: {
        item: Object
    },
    methods: {
        stringify: function(spec_val) {
            if (typeof spec_val == "undefined" || !spec_val.length) {
                return "";
            } else if (typeof spec_val == "string") {
                return spec_val;
            } else if (typeof spec_val == "object" && spec_val.length) {
                return spec_val.join(", ");
            }
        }
    }
};
</script>
<style scoped>
.bordered {
    border-bottom: 1px solid #e0e0e0;
}
</style>
