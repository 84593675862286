<template>
    <article
        class="card tile box is-child has-background-white"
        style="min-height: 475px; opacity: 90%"
        :class="{
            invisible: item.disabled,
            abs: !standalone,
        }"
    >
        <div class="columns is-mobile is-gapless mb-0 is-multiline">
            <div class="column is-four-fifths">
                <a
                    class="title product-link"
                    :href="item['Product Website']"
                    target="_blank"
                >
                    <p class="is-size-5 mb-2">{{ item["Product Name"] }}</p>
                </a>
            </div>
            <div class="column is-one-fifth">
                <div
                    class="purple-badge is-pulled-right"
                    v-if="item['Certified'] == 'Yes'"
                >
                    <b-tooltip
                        label="Certified application"
                        position="is-left"
                        animated
                        type="is-dark"
                    >
                        <LicenseIcon :size="28"></LicenseIcon>
                    </b-tooltip>
                </div>
            </div>
            <div class="column is-full">
                <span class="is-size-6">
                    {{ item["Manufacturer"] }}
                </span>
            </div>
        </div>
        <div class="mb-5">
            <img
                v-if="item['Product Image']"
                :alt="item['Product Name']"
                :src="item['Product Image']['0']['thumbnails']['large']['url']"
                class="py-0 px-0"
                style="border-radius: 5px"
            />
            <div v-else class="placeholder"></div>
        </div>
        <div class="download-parent">
            <div class="download">
                <div class="columns is-centered is-mobile is-vcentered">
                    <div class="column is-3">
                        <b-tooltip
                            label="Vendor application"
                            position="is-left"
                            animated
                            type="is-dark"
                        >
                            <a
                                v-if="item['OEM Github Repo']"
                                class="is-size-7 product-link"
                                :href="item['OEM Github Repo']"
                                target="_blank"
                            >
                                OEM
                            </a>
                        </b-tooltip>
                    </div>
                    <div
                        class="column is-6"
                        :class="{ 'download-border': hasDownloadLinks(item) }"
                        v-if="hasDownloadLinks(item)"
                    >
                        <span class="is-size-7 border-title"> Download </span>
                        <div class="columns is-mobile is-vcentered py-0 px-2">
                            <!-- <div class="column is-full">
                                    <p
                                            class="is-size-6 has-text-centered mb-2"
                                            v-if="item['Version']"
                                        >
                                        <span  span class="is-size-7">{{ item["Version"] }}</span>
                                    </p>
                                </div> -->
                            <div
                                v-if="item['Win32 URL']"
                                class="column px-0 is-narrow dl-link"
                            >
                                <b-tooltip
                                    label="Windows (32-bit)"
                                    position="is-left"
                                    animated
                                    type="is-dark"
                                >
                                    <a :href="item['Win32 URL']">
                                        <MicrosoftWindowsClassicIcon
                                            :width="32"
                                            :height="32"
                                        />
                                    </a>
                                </b-tooltip>
                            </div>
                            <div
                                v-if="item['Win64 URL']"
                                class="column px-0 is-narrow dl-link"
                            >
                                <b-tooltip
                                    label="Windows (64-bit)"
                                    position="is-left"
                                    animated
                                    type="is-dark"
                                >
                                    <a :href="item['Win64 URL']">
                                        <MicrosoftWindowsIcon
                                            :width="32"
                                            :height="32"
                                        />
                                    </a>
                                </b-tooltip>
                            </div>
                            <div
                                v-if="item['MacOS URL']"
                                class="column px-0 is-narrow dl-link"
                            >
                                <b-tooltip
                                    label="MacOS"
                                    position="is-left"
                                    animated
                                    type="is-dark"
                                >
                                    <a :href="item['MacOS URL']">
                                        <AppleIcon :width="32" :height="32" />
                                    </a>
                                </b-tooltip>
                            </div>
                            <div
                                v-if="item['Linux URL']"
                                class="column px-0 is-narrow dl-link"
                            >
                                <b-tooltip
                                    label="Linux"
                                    position="is-left"
                                    animated
                                    type="is-dark"
                                >
                                    <a :href="item['Linux URL']">
                                        <LinuxIcon :width="32" :height="32" />
                                    </a>
                                </b-tooltip>
                            </div>
                            <div
                                v-if="item['Android URL']"
                                class="column px-0 is-narrow dl-link"
                            >
                                <b-tooltip
                                    label="Android"
                                    position="is-left"
                                    animated
                                    type="is-dark"
                                >
                                    <AndroidIcon :width="32" :height="32" />
                                </b-tooltip>
                            </div>
                            <div
                                v-if="item['iOS URL']"
                                class="column px-0 is-narrow dl-link"
                            >
                                <b-tooltip
                                    label="iOS"
                                    position="is-left"
                                    animated
                                    type="is-dark"
                                >
                                    <AppleIosIcon />
                                </b-tooltip>
                            </div>
                        </div>
                    </div>
                    <div v-else class="column is-6"></div>
                    <div
                        v-if="item['GitHub']"
                        class="column is-3 has-text-right"
                    >
                        <b-tooltip
                            label="GitHub Repo"
                            position="is-left"
                            animated
                            type="is-dark"
                        >
                            <a :href="item['GitHub']" target="_blank">
                                <GithubIcon :width="32" :height="32" />
                            </a>
                        </b-tooltip>
                    </div>
                    <div v-else class="column is-3"></div>
                </div>
            </div>
        </div>
    </article>
</template>

<script>
import GithubIcon from "mdi-vue/Github.vue";
import MicrosoftWindowsClassicIcon from "mdi-vue/MicrosoftWindowsClassic.vue";
import MicrosoftWindowsIcon from "mdi-vue/MicrosoftWindows.vue";
import AppleIosIcon from "mdi-vue/AppleIos.vue";
import AppleIcon from "mdi-vue/Apple.vue";
import LinuxIcon from "mdi-vue/Linux.vue";
import AndroidIcon from "mdi-vue/Android.vue";
import OpenInNewIcon from "mdi-vue/OpenInNew.vue";
import LicenseIcon from "mdi-vue/License.vue";
import InformationOutlineIcon from "mdi-vue/InformationOutline.vue";

export default {
    name: "DeviceCard",
    components: {
        GithubIcon,
        MicrosoftWindowsClassicIcon,
        MicrosoftWindowsIcon,
        AppleIosIcon,
        AppleIcon,
        LinuxIcon,
        AndroidIcon,
        InformationOutlineIcon,
        LicenseIcon,
    },
    props: {
        item: Object,
        standalone: Boolean || null,
    },
    methods: {
        hasDownloadLinks(device) {
            if (
                device["Win32 URL"] ||
                device["Win64 URL"] ||
                device["MacOS URL"] ||
                device["Linux URL"] ||
                device["iOS URL"] ||
                device["Android URL"]
            ) {
                return true;
            } else {
                return false;
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.purple-badge {
    color: #5c1596;
}
.product-link {
    text-decoration: none !important;
    border-bottom: 1px solid transparent;
    &:hover {
        text-decoration: underline !important;
    }
}
.abs {
    position: absolute;
}
.card {
    width: 280px;
    transition: opacity 0.2s ease-in-out;
    &:hover {
        opacity: inherit !important;
      box-shadow: 0 19px 38px rgba(92,21,150,0.30), 0 15px 12px rgba(92,21,150,0.22);
    }
}
.placeholder {
    min-width: 250px;
    min-height: 250px;
}
.download-parent {
    position: absolute;
    width: 85%;
    bottom: 5%;
}
.download-border {
    border: 2px solid #e2e2e2;
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
}
.border-title {
    position: absolute;
    top: -20px;
    left: 88px;
    padding-left: 5px;
    padding-right: 5px;
    background: white;
}
.dl-link {
    cursor: pointer;
}

.mdi-error {
    color: #cf0000 !important;
    opacity: 84% !important;
    transition: 0.2s ease-in-out !important;
    &:hover {
        opacity: 84% !important;
    }
    &:active {
        opacity: 95% !important;
    }
    &:disabled {
        opacity: 20% !important;}
}
</style>
