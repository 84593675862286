<template>
  <div>
    <div v-if="isLoading">
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title mx-3">Submitting app</p>

          <button
              type="button"
              class="delete"
              @click="$emit('close')" />
        </header>
        <section class="modal-card-body has-text-centered is-size-6">
          <div v-if="!isResponded" class="mt-6">
            <b-loading v-model="isLoading" :is-full-page="false" class="mt-5">
            </b-loading>
            <p class="mt-2">
              Submitting app for review
            </p>
          </div>
          <div v-else-if="isSuccessful">
            <CheckIcon :size="40"></CheckIcon>
            <p class="mt-2">
              Successfully submitted app for review
            </p>
          </div>
          <div v-else class="mt-3">
            <AlertCircleIcon :size="40"></AlertCircleIcon>
            <p class="has-text-black mt-3">Failed to submit device</p>
            <p class="has-text-danger">{{ responseText }}</p>
          </div>
        </section>
      </div>
    </div>
    <form v-else @submit="postDevice" action="">
      <div class="modal-card" style="width: auto">

        <header class="modal-card-head">
          <p class="modal-card-title">Submit a new LSL integration</p>

          <button
              type="button"
              class="delete"
              @click="$emit('close')" />
        </header>
        <section class="modal-card-body">
          <p class="has-text-grey is-size-7 mb-2">
                <span v-if="githubEmail">
                    Submitting as 
                    <strong class="mr-1">{{ githubUsername }}</strong>
                    <GithubIcon></GithubIcon>
                </span>
            <span v-else>
                    Submitting anonymously. Please login through GitHub <br /> to help expedite the review process.
                </span>
          </p>
          <b-field label="Your contact email">
            <b-input
                v-model="email"
                ref="emailField"
                placeholder="example@domain.com"
                type="email"
                required
                clearable
            >
            </b-input>
          </b-field>
          <hr class="my-5" />
          <b-field label="Manufacturer">
            <b-autocomplete
                v-model="manufacturer"
                :data="filteredDataArray('Manufacturer', manufacturer)"
                placeholder="Manufacturer"
                required
                clearable
            >
            </b-autocomplete>
          </b-field>
          <b-field label="Product name">
            <b-input
                v-model.trim="product_name"
                placeholder="Device / App name"
                required>
            </b-input>
          </b-field>
          <b-field label="Product type">
            <b-select v-model="product_type" expanded required placeholder="Select product type">
              <option>Hardware</option>
              <option>Software</option>
            </b-select>
          </b-field>
          <b-field label="Signal type">
            <b-taginput
                v-model="signal_types"
                class="control"
                autocomplete
                allow-new
                open-on-focus
                placeholder="EEG..."
                @typing="updateFilteredSignalTags"
                :data="filteredSignalTags">
            </b-taginput>
          </b-field>
          <b-field label="Image" class="mb-0">
            <b-upload
                drag-drop
                expanded

                @input="setImage"
                ref="imageUploader"
                v-bind:style="imgStyle">
                    <span v-if="imgErrors.length" class="is-pulled-right">
                        <AlertCircleIcon class="mdi-error"></AlertCircleIcon>
                        </span>
              <section class="section">
                <ul class="has-text-centered mb-3">
                  <li v-for="(error, i) in imgErrors" v-bind:key="i">
                    <span class="is-size-7 has-text-danger"><strong>{{ error }}</strong></span>
                  </li>
                </ul>
                <div class="content has-text-centered" v-if="imgPath.length === 0 || imgErrors.length">
                  <p>
                    <UploadIcon :size="64"></UploadIcon>
                  </p>
                  <p v-if="!imgErrors.length">Drop image here or click to upload</p>
                  <p v-else>Drop image here or click to upload</p>
                </div>
              </section>

            </b-upload>

          </b-field>

          <div class="tags">
                <span v-for="(file, index) in dropFiles"
                      :key="index"
                      class="tag is-primary">
                    {{ file.name }}
                    <button class="delete is-small"
                            type="button"
                            @click="deleteDropFile(index)">
                    </button>
                </span>
          </div>
          <b-field label="Product website">
            <b-input
                v-model.trim="product_url"
                type="url"
                placeholder="Product URL"
                required>
            </b-input>
          </b-field>
          <b-field label="Manufacturer website">
            <b-input
                v-model.trim="manufacturer_url"
                type="url"
                placeholder="Manufacturer URL"
                required>
            </b-input>
          </b-field>
          <b-field label="GitHub Repo">
            <b-input
                v-model.trim="github_url"
                type="url"
                placeholder="https://github.com/[user]/[repo]..."
            >
            </b-input>
          </b-field>
          <b-field label="Win32 URL">
            <b-input
                v-model.trim="win32_url"
                type="url"
            >
            </b-input>
          </b-field>
          <b-field label="Win64 URL">
            <b-input
                v-model.trim="win64_url"
                type="url"
            >
            </b-input>
          </b-field>
          <b-field label="MacOS URL">
            <b-input
                v-model.trim="macos_url"
                type="url"
            >
            </b-input>
          </b-field>
          <b-field label="Linux URL">
            <b-input
                v-model.trim="linux_url"
                type="url"
            >
            </b-input>
          </b-field>
          <b-field label="Source Code URL">
            <b-input
                v-model.trim="source_code_url"
                type="url"
            >
            </b-input>
          </b-field>
          <hr />
          <b-field>
            <b-checkbox v-model="accept_tos" required class="control">
              <span>I agree to the
                <router-link to="/tos/">Terms of Service</router-link> and
                <router-link to="/privacy/">Privacy Policy</router-link>.</span>
            </b-checkbox>
          </b-field>
          <b-field group-multiline>
            <b-checkbox v-model="copyright_waiver" required class="control mb-3">
              <span>I am an authorized copyright holder of the <br /> materials submitted in this form.</span>
            </b-checkbox>
          </b-field>
        </section>

        <footer class="modal-card-foot">
          <button
              class="button is-primary"

              :disabled="imgErrors.length > 0">Submit
          </button>
        </footer>

      </div>

    </form>
  </div>
</template>

<script>
import UploadIcon from "mdi-vue/Upload.vue";
import lambdaService from "@/lambdaService";
import GithubIcon from "mdi-vue/Github.vue";
import AlertCircleIcon from "mdi-vue/AlertCircle.vue";
import CheckIcon from "mdi-vue/Check.vue";
import { mapGetters } from "vuex";

export default {
  name: "SubmitAppForm",
  props: ["valueSets"],
  components: {
    UploadIcon,
    GithubIcon,
    AlertCircleIcon,
    CheckIcon
  },
  mounted() {
    if (this.githubEmail) {
      this.email = this.githubEmail;
    }
    if (this.githubToken) {
      this.token = this.githubToken;
    }
    if (this.githubUsername) {
      this.username = this.githubUsername;
    }
  },
  data() {
    return {
      imgErrors: [],
      filteredSignalTags: [],

      dropFiles: [],
      // manufacturer: "Test Manufacturer",
      // product_name: "Test Product",
      // signal_type: "EEG",
      // product_url: "https://intheon.io",
      // manufacturer_url: "https://intheon.io",
      // github_url: "https://intheon.io",
      imgPath: "",
      product_type: null,
      type: "Hardware",
      email: "",
      token: "",
      username: "",
      manufacturer: "",
      product_name: "",
      signal_type: "",
      signal_types: [],
      product_url: "",
      manufacturer_url: "",
      github_url: "",
      win32_url: "",
      win64_url: "",
      macos_url: "",
      linux_url: "",
      source_code_url: "",
      version: "",
      release_date: "",
      imgb64: "",
      imgMeta: {},
      isLoading: false,
      isResponded: false,
      accept_tos: false,
      copyright_waiver: false,
      isSuccessful: null,
      responseText: ""
    };
  },
  computed: {
    ...mapGetters({
      githubEmail: "getGithubEmail",
      githubUsername: "getGithubUsername",
      githubToken: "getGithubToken"
    }),

    getEmail: function() {
      return this.githubEmail || this.email;
    },
    device: function() {
      return {
        Email: this.email,
        Token: this.token,
        Username: this.username,
        Manufacturer: this.manufacturer,
        "Product Name": this.product_name,
        "Signal Type": this.signal_types,
        "Manufacturer Website": this.manufacturer_url,
        "Product Website": this.product_url,
        Type: this.product_type,
        GitHub: this.github_url,
        "Release Date": this.release_date,
        Version: this.version,
        ImageBase64: this.imgb64,
        ImageMeta: this.imgMeta,
        "Win32 URL":  this.win32_url,
        "Win64 URL":  this.win64_url,
        "MacOS URL":  this.macos_url,
        "Linux URL":  this.linux_url,
        "Source Code URL":  this.source_code_url,
      };
    },
    imgStyle: function() {
      let style = {};

      if (!this.imgErrors.length) {
        style = {
          "background-image": `url(${this.imgPath})`,
          "background-size": "100%"
        };
      } else {
        style = {
          background: `linear-gradient(rgba(255, 255, 255, 0.85), rgba(255, 255, 255, 0.95)), url(${
              this.imgPath
          }) no-repeat center`
        };
      }
      return style;
    }
  },
  methods: {
    filteredDataArray(key, input) {
      return this.valueSets[key].filter(value => {
        return (
            value
                .toString()
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
        );
      });
    },
    updateFilteredSignalTags(input) {
      console.log(this.signal_types);
      this.filteredSignalTags = this.filteredDataArray(
          "Signal Type",
          input
      );
    },
    removeSignal(index) {
      this.signal_types.splice(index, 1);
    },
    deleteDropFile(index) {
      this.dropFiles.splice(index, 1);
    },
    postDevice() {
      this.isLoading = true;
      lambdaService.submitDevice(this.device).then(
          response => {
            this.isResponded = true;
            this.isSuccessful = response.status === 200;
            this.responseText =
                response.data.status || "An error occured.";
            setTimeout(() => {
              this.isLoading = false;
              this.isResponded = false;
              if (this.isSuccessful) {
                this.$emit("submit-success", true);
              }
            }, 3000);
          },
          error => {
            console.log(error);
          }
      );
    },
    avgBorderColor(img, border) {
      // from https://stackoverflow.com/questions/29387084/get-average-outer-pixel-color-of-image
      var canvas = document.createElement("canvas"), // create a canvas element
          ctx = canvas.getContext("2d"), // get context
          w = img.naturalWidth, // get actual width..
          h = img.naturalHeight;

      canvas.width = w; // set canvas size
      canvas.height = h;

      ctx.drawImage(img, 0, 0); // draw in image

      // do checks:, for example:
      //if (border*2 > canvas.width || border*2 > canvas.height) throw "Image too small!";

      // get borders, avoid overlaps (though it does not really matter in this case):
      var top = ctx.getImageData(0, 0, w, border).data;
      var left = ctx.getImageData(0, border, border, h - border * 2).data;
      var right = ctx.getImageData(
          w - border,
          border,
          border,
          h - border * 2
      ).data;
      var bottom = ctx.getImageData(0, h - border, w, border).data;

      var r = 0,
          g = 0,
          b = 0,
          cnt = 0;

      // count pixels and add up color components: (see function below)
      countBuffer(top);
      countBuffer(left);
      countBuffer(right);
      countBuffer(bottom);

      // calc average
      r = (r / cnt + 0.5) | 0;
      g = (g / cnt + 0.5) | 0;
      b = (b / cnt + 0.5) | 0;

      return [r, g, b];

      function countBuffer(data) {
        var i = 0,
            len = data.length;
        while (i < len) {
          r += data[i++]; // add red component etc.
          g += data[i++];
          b += data[i++];
          i++;
          cnt++; // count one pixel
        }
      }
    },
    setImageBase64(b64_data) {
      this.imgb64 = b64_data;
    },
    setImage(file) {
      let uploaderEl = this.$refs.imageUploader.$el;
      let reader = new FileReader();
      let MIN = 300;
      let MAX = 1000;
      let RATIO = 1;
      let avgBorderColor = this.avgBorderColor;
      let imgErrors = (this.imgErrors = []);
      let setImageBase64 = this.setImageBase64;
      this.imgMeta = {
        name: file.name,
        lastModified: file.lastModified,
        size: file.size
      };
      if (file.type.startsWith("image/")) {
        let image = new Image();
        this.imgPath = URL.createObjectURL(file);
        reader.readAsDataURL(file);
        reader.onload = function(e) {
          image.src = e.target.result;
          image.onload = function() {
            setImageBase64(image.src);
            var height = this.height;
            var width = this.width;

            var [r, g, b] = avgBorderColor(image, 1);
            let isPng = file.type == "image/png";
            if (isPng && (r > 1 || g > 1 || b > 1)) {
              imgErrors.push(
                  `Image must have a white background`
              );
            } else if (!isPng && (r < 254 || g < 254 || b < 254)) {
              imgErrors.push(
                  `Image must have a white background`
              );
            }
            if (width / height > 1.01 || width / height < 0.99) {
              imgErrors.push(
                  `Image must have a 1:1 aspect ratio`
              );
            }
            if (height > MAX || width > MAX) {
              imgErrors.push(
                  `Image size must not exceed ${MAX}px`
              );
            } else if (width < MIN || height < MIN) {
              imgErrors.push(
                  `Image size must be larger than ${MIN}px x ${MIN}px`
              );
            }
            return false;
          };
          return true;
        };
      } else {
        imgErrors.push(`Selected file is not a valid image`);
      }
    }
  }
};
</script>

<style>
.upload.is-expanded:before {
  content: "";
  float: left;
  padding-top: 100%;
}
</style>
